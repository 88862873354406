import React from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import window from "global/window";

function SummerKindnessSeriesWeek7() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "teamwork_web" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "craftandcreate" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "MOJOandtheMAZE" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 7"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 7",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="All About Teamwork!"
        headerSub="Kindness Habit 7:"
        headerText={
          <>
            Work together towards
            <br />a shared goal
          </>
        }
        headerImage={headerImage}
        whatisIt="Ever played a baseball game by yourself? Not very fun, right? When kids play together, they learn important skills, like how to collaborate, solve problems, share, and compromise. And just like with sports, practice makes perfect! Learning from play? That’s what we call a home run :)"
        whyIsItImportant="As all adults will attest, stop exercising your muscles and they seem to disappear overnight. Skills like collaboration are the same - if you don’t use them, you lose them. Collaborative family play builds skills like sharing and compromise, both of which help cultivate empathy. So get that family game night back on the books! It’s much more fun than lifting weights anyway."
        habbits={[
          {
            header: "Role Call for Family Meetings:",
            text: "Try holding regular family meetings and have kids rotate roles like timekeeper and note-taker. Keep it upbeat, give compliments, and use this time to collaboratively problem-solve and make family decisions!",
          },
          {
            header: "Learn One New Thing:",
            text: "Encourage kids to learn “one new thing” about someone each day. The habit requires kids to listen deeper, ask questions, and focus more on one another.",
          },
          {
            header: "Rock, Paper, Scissors:",
            text: "Believe it or not, even a simple game of rock-paper-scissors can make an impact! Besides being fun, it’s proven to help kids collaborate on rules and work out conflicts on their own.",
          },
          {
            header: "We > Me:",
            text: 'Practice using “we” and "us" statements instead of “me” or “I” statements. It helps kids focus on the similarities between groups, instead of the differences.',
          },
        ]}
        activitiesText="Practice Teamwork at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Bruce's Dream House",
            text: "There's just one catch...",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/dream_house.pdf";
            },
          },
          {
            icon: activity2,
            header: "Get Your Game On!",
            text: "5 team building ideas",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/teambuilding.pdf";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek7;
